<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>Tweets</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ion-grid>
        <ion-row>
          <!-- first col for first card -->
          <ion-col size="12" size-sm="6" size-xl="4">
            <ion-card class="ios hydrated">
              <div
                class="imageholder"
                style="background-image: url(https://cdn.prod.www.spiegel.de/images/f266498f-fbf5-4dfd-8513-cb9b54cda78e_w948_r1.77_fpx32.89_fpy50.jpg)"
              >
                <div class="cal-box">
                  <h2>18</h2>
                  <ion-text>MÄR</ion-text>
                </div>
                <div class="img-title">
                  <ion-item lines="none" color="none" class="title-gradient">
                    <ion-icon size="large" :icon="newspaper" slot="start"></ion-icon>
                    <ion-card-subtitle
                      role="heading"
                      aria-level="3"
                      class="ion-inherit-color ios hydrated"
                    >Webseite Post</ion-card-subtitle>
                    <ion-thumbnail slot="end">
                      <img src="../../assets/images/logos/logoBBV_500px.png" />
                    </ion-thumbnail>
                  </ion-item>
                </div>
              </div>
              <ion-card-header class="ion-inherit-color ios hydrated">
                <ion-card-title
                  role="heading"
                  aria-level="2"
                  class="ion-inherit-color ios hydrated"
                >Weiße Weste hält auch im dritten Spiel</ion-card-title>
                <ion-card-subtitle class="ion-inherit-color ios hydrated">Kategorie: Herren</ion-card-subtitle>
              </ion-card-header>
              <ion-card-content class="ios card-content-ios hydrated">
                <ion-text>Drei Spiele - drei Siege! So lautet die Biland der Guardians of the Irony in diesem Jahr. Damit klettet das Team von Starlord Peter in der Tabelle auf Platz 2.</ion-text>
              </ion-card-content>
              <ion-item>
                <ion-icon size="small" :icon="heartOutline" slot="start"></ion-icon>
                <ion-icon size="small" :icon="notifications" slot="start"></ion-icon>
                <ion-icon size="small" :icon="shareSocial" slot="start"></ion-icon>
                <ion-button fill="clear" slot="end">Mehr lesen</ion-button>
              </ion-item>
            </ion-card>
          </ion-col>
          <!-- second col for second card -->
          <ion-col size="12" size-sm="6" size-xl="4">
            <ion-card class="ios hydrated">
              <div
                class="imageholder"
                style="background-image: url(https://redhawks-potsdam.de/wp-content/uploads/2020/06/Shared_Link_Image_Presse_Robyn-1170x630.jpg)"
              >
                <div class="cal-box">
                  <h2>18</h2>
                  <ion-text>MÄR</ion-text>
                </div>
                <div class="img-title">
                  <ion-item lines="none" color="none" class="title-gradient">
                    <ion-icon size="large" :icon="logoTwitter" slot="start"></ion-icon>
                    <ion-card-subtitle
                      role="heading"
                      aria-level="3"
                      class="ion-inherit-color ios hydrated"
                    >Twitter Message</ion-card-subtitle>
                    <ion-thumbnail slot="end">
                      <img src="../../assets/images/logos/logoBBV_500px.png" />
                    </ion-thumbnail>
                  </ion-item>
                </div>
              </div>
              <ion-card-header class="ion-inherit-color ios hydrated">
                <ion-card-title
                  role="heading"
                  aria-level="2"
                  class="ion-inherit-color ios hydrated"
                >Beitragssenkung beschlossen</ion-card-title>
              </ion-card-header>
              <ion-card-content class="ios card-content-ios hydrated">
                <ion-text>Auf der heutigen außerordentlichen Mitgliederversammlung sind die Mitglieder dem Antrag des Vorstands gefolgt und haben eine einmalige, für das laufendende Kalenderjahr gültige Beitragssenkung beschlossen.</ion-text>
              </ion-card-content>
              <ion-item>
                <ion-icon size="small" :icon="heart" slot="start"></ion-icon>
                <ion-icon size="small" :icon="notifications" slot="start"></ion-icon>
                <ion-icon size="small" :icon="shareSocial" slot="start"></ion-icon>
                <ion-button fill="clear" slot="end">Mehr lesen</ion-button>
              </ion-item>
            </ion-card>
          </ion-col>
          <!-- third col for third card -->
          <ion-col size="12" size-sm="6" size-xl="4">
            <ion-card class="ios hydrated">
              <div
                class="imageholder"
                style="background-image: url(https://img.br.de/8e37993a-4deb-4516-b4b4-98df2ecbcbb1.jpeg?q=80&rect=48%2C76%2C3703%2C2083&w=1200);"
              >
                <div class="cal-box">
                  <h2>18</h2>
                  <ion-text>MÄR</ion-text>
                </div>
                <div class="img-title">
                  <ion-item lines="none" color="none" class="title-gradient">
                    <ion-icon size="large" :icon="logoFacebook" slot="start"></ion-icon>
                    <ion-card-subtitle
                      role="heading"
                      aria-level="3"
                      class="ion-inherit-color ios hydrated"
                    >Facebook Post</ion-card-subtitle>
                    <ion-thumbnail slot="end">
                      <img src="../../assets/images/logos/logoBBV_500px.png" />
                    </ion-thumbnail>
                  </ion-item>
                </div>
              </div>
              <ion-card-header class="ion-inherit-color ios hydrated">
                <ion-card-title
                  role="heading"
                  aria-level="2"
                  class="ion-inherit-color ios hydrated"
                >Weiße Weste hält auch im dritten Spiel</ion-card-title>
              </ion-card-header>
              <ion-card-content class="ios card-content-ios hydrated">
                <ion-text>Drei Spiele - drei Siege! So lautet die Biland der Guardians of the Irony in diesem Jahr. Damit klettet das Team von Starlord Peter in der Tabelle auf Platz 2.</ion-text>
              </ion-card-content>
              <ion-item>
                <ion-icon size="small" :icon="heartOutline" slot="start"></ion-icon>
                <ion-icon size="small" :icon="notifications" slot="start"></ion-icon>
                <ion-icon size="small" :icon="shareSocial" slot="start"></ion-icon>
                <ion-button fill="clear" slot="end">Mehr lesen</ion-button>
              </ion-item>
            </ion-card>
          </ion-col>
          <!-- forth col for forth card -->
          <ion-col size="12" size-sm="6" size-xl="4">
            <ion-card class="ios hydrated">
              <div
                class="imageholder"
                style="background-image: url(https://ionicframework.com/docs/demos/api/card/madison.jpg);"
              >
                <div class="cal-box">
                  <h2>18</h2>
                  <ion-text>MÄR</ion-text>
                </div>
                <div class="img-title">
                  <ion-item lines="none" color="none" class="title-gradient">
                    <ion-icon size="large" :icon="logoInstagram" slot="start"></ion-icon>
                    <ion-card-subtitle
                      role="heading"
                      aria-level="3"
                      class="ion-inherit-color ios hydrated"
                    >Instagram Post</ion-card-subtitle>
                    <ion-thumbnail slot="end">
                      <img src="../../assets/images/logos/logoBBV_500px.png" />
                    </ion-thumbnail>
                  </ion-item>
                </div>
              </div>
              <ion-card-header class="ion-inherit-color ios hydrated">
                <ion-card-title
                  role="heading"
                  aria-level="2"
                  class="ion-inherit-color ios hydrated"
                >Beitragssenkung beschlossen</ion-card-title>
              </ion-card-header>
              <ion-card-content class="ios card-content-ios hydrated">
                <ion-text>Auf der heutigen außerordentlichen Mitgliederversammlung sind die Mitglieder dem Antrag des Vorstands gefolgt und haben eine einmalige, für das laufendende Kalenderjahr gültige Beitragssenkung beschlossen.</ion-text>
              </ion-card-content>
              <ion-item>
                <ion-icon size="small" :icon="heartOutline" slot="start"></ion-icon>
                <ion-icon size="small" :icon="notifications" slot="start"></ion-icon>
                <ion-icon size="small" :icon="shareSocial" slot="start"></ion-icon>
                <ion-button fill="clear" slot="end">Mehr lesen</ion-button>
              </ion-item>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  /*     IonImg,
   */ IonItem,
  /*     IonLabel, */
  IonMenuButton,
  IonPage,
  IonRow,
  /*     IonSegment,
    IonSegmentButton, */
  IonText,
  IonTitle,
  IonToolbar,
  IonThumbnail,
} from "@ionic/vue";
import {
  pin,
  wine,
  warning,
  walk,
  rocket,
  logoTwitter,
  logoFacebook,
  logoInstagram,
  newspaper,
  heartOutline,
  heart,
  notifications,
  shareSocial,
} from "ionicons/icons";

import { defineComponent } from 'vue';
export default defineComponent({
  name: "NewsList",

  components: {
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    /*         IonImg,
     */ IonItem,
    /*         IonLabel, */
    IonMenuButton,
    IonPage,
    IonRow,
    /*         IonSegment,
        IonSegmentButton, */
    IonText,
    IonTitle,
    IonToolbar,
    IonThumbnail,
  },
  methods: {
    segmentChanged(ev: any): any {
      console.log("Segment changed", ev);
    },
  },
  setup() {
    return {
      pin,
      wine,
      warning,
      walk,
      rocket,
      logoTwitter,
      logoFacebook,
      logoInstagram,
      newspaper,
      heartOutline,
      heart,
      notifications,
      shareSocial,
    };
  },
});
</script>

<style scoped>
ion-card-title {
  font-family: var(--ion-font-family-light);
  color: var(--ion-color-dark);
}

ion-icon {
  color: var(--ion-color-primary);
}

ion-item.title-gradient > ion-icon {
  color: var(--ion-color-primary-contrast);
}

ion-col > ion-card-subtitle,
ion-item > ion-card-subtitle {
  color: var(--ion-color-primary-contrast);
  font-family: var(--ion-font-family-semi-bold);
  line-height: 100%;
}

ion-col > ion-thumbnail,
ion-item > ion-thumbnail {
  --border-radius: 25%;
  border: 1px var(--ion-color-primary) solid;
  width: 2rem;
  height: 2rem;
}

ion-col > ion-thumbnail > img,
ion-item > ion-thumbnail > img {
  width: calc(2rem - 2px);
  height: calc(2rem - 2px);
}

ion-button {
  color: var(--ion-color-primary);
}

ion-card {
  border-radius: 0.5rem;
}

ion-card-content {
  color: var(--ion-color-dark);
}

.card-text {
  font-family: var(--ion-font-family);
  font-size: 20px;
  color: var(--ion-color-healy-darkblue);
}

.img-title {
  position: absolute;
  bottom: 0;
  width: 100%;
  /*     left: 2rem;
 */
}

.cal-box {
  position: absolute;
  top: 1rem;
  left: 1rem;
  border: var(--ion-color-primary-contrast) solid 1px;
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
  border-radius: 0.5rem;
  padding: 0;
  height: 3rem;
  width: 3rem;
  text-align: center;
}

.cal-box > h2 {
  margin-top: 0.125rem;
  margin-bottom: -0.125rem;
  font-size: 1.25rem;
  font-weight: bold;
}

.cal-box > ion-text {
  font-family: var(--ion-font-family-medium);
  font-size: 0.75rem;
  line-height: 100%;
}

.imageholder {
  position: relative;
  width: 100%;
  padding-bottom: 56.75%;
  background-size: cover;
}

.title-gradient {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.4) 60%,
    rgba(0, 0, 0, 0) 100%
  );
}
</style>